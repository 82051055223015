import { instanceNotice } from '@/api';

const fetchAllNotices = () => {
  return instanceNotice.get();
};

const fetchNotice = (id) => {
  return instanceNotice.get(`/${id}`);
};

export { fetchAllNotices, fetchNotice };
