<template>
  <div class="footer-container">
    <footer>
      <div class="footer-logo">
        <img src="../../assets/images/logo.png" alt="d.AntWort Logo" />
      </div>
      <div>
        <span class="footer-menu-item">
          <a
            href="http://jj.tovr360.com/ko/"
            target="_blank"
            rel="noopener noreferrer"
            >소개</a
          >
        </span>
        <span class="footer-menu-item">
          <router-link to="/notice">공지사항</router-link>
        </span>
        <span class="footer-menu-item">
          <router-link to="/contact">문의하기</router-link>
        </span>
        <span class="footer-menu-item">
          <router-link to="/privacy"><b>개인정보보호정책</b></router-link>
        </span>
        <span class="footer-menu-item">
          <router-link to="/terms">이용약관</router-link>
        </span>
      </div>
      <div class="footer-copy">
        <p>Copyright © 2022 d.Antwort. All rights Reserved.</p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.footer-container {
  padding: 30px 40px;
  background-color: #f4f4f4;
  height: 170px;
  position: relative;
}
.footer-logo {
  margin-bottom: 25px;
}
.footer-menu-item * {
  font-size: 15px;
  color: #7e7e7e;
}
.footer-menu-item::after {
  content: ' | ';
  color: #7e7e7e;
}
.footer-menu-item:last-child::after {
  content: '';
}
.footer-copy {
  margin-top: 15px;
  font-size: 12px;
  color: #929292;
}
.footer-copy > p {
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .footer-container {
    padding: 20px 10px;
  }
}
</style>
