import { instanceTag } from '@/api';

const fetchAllTags = () => {
  return instanceTag.get();
};
const fetchSpotsWithTag = (payload) => {
  return instanceTag.get(`/tag_list/${payload.id}?page=${payload.page}`);
};

export { fetchAllTags, fetchSpotsWithTag };
