import { instanceSpot } from '@/api';

const fetchAllSpots = (page) => {
  return instanceSpot.get(`?page=${page}`);
};
const fetchDetailSpot = (id) => {
  return instanceSpot.get(`/${id}`);
};
const fetchLikesCount = (id) => {
  return instanceSpot.get(`/get_likes_count/${id}`);
};
const fetchCommentsCount = (id) => {
  return instanceSpot.get(`/get_comments_count/${id}`);
};
const fetchBookmarksCount = (id) => {
  return instanceSpot.get(`/get_bookmarks_count/${id}`);
};
const fetchTags = (id) => {
  return instanceSpot.get(`/get_tags/${id}`);
};
const fetchComments = (id) => {
  return instanceSpot.get(`/get_comments/${id}`);
};

export {
  fetchAllSpots,
  fetchDetailSpot,
  fetchLikesCount,
  fetchCommentsCount,
  fetchBookmarksCount,
  fetchTags,
  fetchComments,
};
