import {
  getIdFromCookie,
  getAuthFromCookie,
  getUserFromCookie,
} from '@/utils/cookies.js';

const state = {
  // Spots
  spots: [],
  spotsLastPage: 0,
  spot: {},
  pos: 0,
  lat: 0,
  lon: 0,
  isOk: false,
  tags: [],
  comments: [],
  likesCount: 0,
  commentsCount: 0,
  bookmarksCount: 0,

  // Hashtags
  hashtags: [],
  spotsWithTag: [],

  // Maps
  maps: [],
  mapLat: 0,
  mapLon: 0,
  mapAttractions: [],
  mapCafes: [],
  mapBeaches: [],
  mapHotels: [],
  mapActivities: [],

  // Auth
  userId: getIdFromCookie() || '',
  email: getUserFromCookie() || '',
  token: getAuthFromCookie() || '',

  // Profile
  currentUser: {},
  currentUserLikes: [],
  currentUserBookmarks: [],
  userLikeSpots: [],
  userBookmarkSpots: [],
  userCommentSpots: [],

  // Notice
  noticeArticles: [],
  noticeArticle: {},

  // Contact
  contact: {},
};

export default state;
