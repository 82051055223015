import { instanceMap } from '@/api';

const fetchMaps = () => {
  return instanceMap.get();
};

const fetchMapsByCat = (cat) => {
  return instanceMap.get(`/get_cats/${cat}`);
};

export { fetchMaps, fetchMapsByCat };
