import { instanceProfile } from '@/api';

const fetchProfile = (id) => {
  return instanceProfile.get(`/${id}`);
};
const fetchUserLikes = (id) => {
  return instanceProfile.get(`/likes/${id}`);
};
const setLike = (data) => {
  return instanceProfile.post(`/likes`, data);
};
const fetchUserBookmarks = (id) => {
  return instanceProfile.get(`/bookmarks/${id}`);
};
const setBookmark = (data) => {
  return instanceProfile.post(`/bookmarks`, data);
};
const deleteLike = (data) => {
  return instanceProfile.delete(`/likes/${data.id}/${data.place}`);
};
const deleteBookmark = (data) => {
  return instanceProfile.delete(`/bookmarks/${data.id}/${data.place}`);
};
const setComment = (data) => {
  return instanceProfile.post(`/comments`, data);
};
const fetchLikesSpotList = (id) => {
  return instanceProfile.get(`/likes_spot_list/${id}`);
};
const fetchBookmarksSpotList = (id) => {
  return instanceProfile.get(`/bookmarks_spot_list/${id}`);
};
const fetchCommentsSpotList = (id) => {
  return instanceProfile.get(`/comments_spot_list/${id}`);
};
const unregister = (id) => {
  return instanceProfile.delete(`/${id}`);
};
const updateUser = (userData) => {
  return instanceProfile.put(`/${userData.id}`, userData);
};

export {
  fetchProfile,
  fetchUserLikes,
  setLike,
  fetchUserBookmarks,
  setBookmark,
  deleteLike,
  deleteBookmark,
  setComment,
  fetchLikesSpotList,
  fetchBookmarksSpotList,
  fetchCommentsSpotList,
  unregister,
  updateUser,
};
