<template>
  <div class="header-container">
    <div class="header-top">
      <h3>
        <a href="/">
          <img src="../../assets/images/jejutovr_logo.png" alt="JEJUTOVR" />
        </a>
      </h3>
      <template v-if="userIsLogin">
        <router-link to="/profile" class="header-profile">
          <img src="../../assets/images/login_icon.png" alt="" />
          <span class="header-profile-text">마이페이지</span>
        </router-link>
      </template>
      <template v-else>
        <router-link to="/login" class="header-profile">
          <img src="../../assets/images/login_icon.png" alt="" />
          <span class="header-profile-text">로그인</span>
        </router-link>
      </template>
    </div>
    <div class="header-bottom">
      <nav>
        <ul>
          <li>
            <router-link to="/destination?page=1">여행지</router-link>
          </li>
          <li>
            <router-link to="/hashtag">해시태그</router-link>
          </li>
          <li>
            <router-link to="/map">지도</router-link>
          </li>
        </ul>
      </nav>
    </div>
    <!-- <b-navbar toggleable="lg" type="light" variant="default">
      <b-navbar-brand>
        <router-link to="/destination?page=1">JEJUTOVR</router-link>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav class="nav-menu-navigation">
        <b-navbar-nav>
          <b-nav-item>
            <router-link to="/destination?page=1">여행지</router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link to="/hashtag">해시태그</router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link to="/map">지도</router-link>
          </b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto">
          <li>
            <template v-if="userIsLogin">
              <router-link to="/profile">마이페이지</router-link>
            </template>
            <template v-else>
              <router-link to="/login">로그인</router-link>
            </template>
          </li>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar> -->
  </div>
</template>

<script>
export default {
  computed: {
    userIsLogin() {
      return this.$store.getters.isLogin;
    },
  },
};
</script>

<style scoped>
.header-container {
  margin-top: 35px;
  margin-bottom: 50px;
  border-bottom: 0.5px solid #c8c8c8;
}
.header-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
}
.header-profile {
  font-size: 18px;
  color: #7e7e7e;
}
.header-profile-text {
  vertical-align: middle;
}
.header-bottom ul {
  display: flex;
  justify-content: flex-start;
  padding-left: 0;
  margin-bottom: 10px;
}
.header-bottom li {
  list-style: none;
  padding-right: 30px;
}
.header-bottom li a {
  font-size: 20px;
  color: #333;
}
.header-bottom .router-link-active {
  text-decoration: none;
  border-bottom: 3px solid #44cac8;
  padding-bottom: 10px;
  font-weight: 700;
}
</style>
