const mutations = {
  // Spots
  fetchSpots(state, spots) {
    state.spots = spots;
  },
  fetchSpotsLastPage(state, page) {
    state.spotsLastPage = page;
  },
  fetchLikesCount(state, count) {
    state.likesCount = count;
  },
  fetchCommentsCount(state, count) {
    state.commentsCount = count;
  },
  fetchBookmarksCount(state, count) {
    state.bookmarksCount = count;
  },
  fetchSpot(state, spot) {
    state.spot = spot;
  },
  fetchSpotPos(state, pos) {
    state.pos = pos;
    state.isOk = !state.isOk;
  },
  fetchSpotLat(state, lat) {
    state.lat = lat;
  },
  fetchSpotLon(state, lon) {
    state.lon = lon;
    state.isOk = !state.isOk;
  },
  fetchTags(state, tags) {
    state.tags = tags;
  },
  fetchComments(state, comments) {
    state.comments = comments;
  },
  fetchHashtags(state, hashtags) {
    state.hashtags = hashtags;
  },

  // Maps
  fetchMaps(state, maps) {
    state.maps = maps;
    state.isOk = !state.isOk;
  },
  fetchMapLat(state, lat) {
    state.mapLat = lat;
  },
  fetchMapLon(state, lon) {
    state.mapLon = lon;
    state.isOk = !state.isOk;
  },
  fetchMapsByCat(state, maps) {
    state.maps = maps;
  },

  // Auth
  setId(state, id) {
    state.userId = id;
  },
  setEmail(state, email) {
    state.email = email;
  },
  setToken(state, token) {
    state.token = token;
  },

  // Profile
  fetchUser(state, user) {
    state.currentUser = user;
  },
  fetchUserLikes(state, likes) {
    state.currentUserLikes = likes;
  },
  fetchUserBookmarks(state, bookmarks) {
    state.currentUserBookmarks = bookmarks;
  },
  fetchLikesSpotList(state, list) {
    state.userLikeSpots = list;
  },
  fetchBookmarksSpotList(state, list) {
    state.userBookmarkSpots = list;
  },
  fetchCommentsSpotList(state, list) {
    state.userCommentSpots = list;
  },
  updateUser(state, user) {
    state.currentUser = user;
  },

  // Hashtag
  fetchSpotsWithTag(state, spots) {
    state.spots = spots;
  },

  // Notice
  fetchNoticeArticles(state, articles) {
    state.noticeArticles = articles;
  },
  fetchNoticeArticle(state, article) {
    state.noticeArticle = article;
  },

  // Contact
  createContact(state, contact) {
    state.contact = contact;
  },
};

export default mutations;
