import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import Toast from 'vue-toastification';
// import videojs from 'videojs-vr';
// import aframe from 'aframe';
// import aframeCtrl from 'aframe-video-controls';
import vueMoment from 'vue-moment';
import Krpano from 'vue-krpano';
import VueCarousel from 'vue-carousel';
// import VueSocialauth from 'vue-social-auth';
import UniversalSocialauth from 'universal-social-auth';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-toastification/dist/index.css';

const options = {
  providers: {
    facebook: {
      clientId: '1480531589030617',
      redirectUri: 'https://localhost:8080',
    },
  },
};

const Oauth = new UniversalSocialauth(axios, options);

Vue.prototype.$axios = axios;
Vue.prototype.$Oauth = Oauth;

const toastOptions = {
  position: 'top-center',
  duration: 100,
  pauseOnHover: false,
};

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Toast, toastOptions);
Vue.use(vueMoment);
Vue.use(Krpano);
Vue.use(VueCarousel);
// Vue.use(VueSocialauth, {
//   providers: {
//     facebook: {
//       clientId: '1480531589030617',
//       redirectUri: '/auth/facebook/callback', // Your client app URL
//     },
//   },
// });
// Vue.prototype.$videojs = videojs;
// Vue.prototype.$aframe = aframe;
// Vue.prototype.$aframeCtrl = aframeCtrl;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
