import { instanceAuth } from '@/api';

const loginUser = (userData) => {
  return instanceAuth.post('/login', userData);
};

const registerUser = (userData) => {
  return instanceAuth.post('/register', userData);
};

const logoutUser = () => {
  return instanceAuth.post('/logout');
};

const forgotPassword = (userData) => {
  return instanceAuth.post('/forgot-password', userData);
};

export { loginUser, registerUser, logoutUser, forgotPassword };
