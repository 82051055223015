import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/';

Vue.use(VueRouter);

const ifAuthTrue = (to, from, next) => {
  // 만약 토큰이 존재하는 상태에서 로그인, 회원가입 페이지 접근 시 메인 페이지로 이동
  if (store.getters.isLogin) {
    next('/');
    return;
  }
  next();
};

const routes = [
  // {
  //   path: '/',
  //   redirect: { name: 'Main' },
  // },
  {
    path: '/',
    name: 'Main',
    component: () => import('../views/main/MainView.vue'),
  },
  {
    path: '/notice',
    name: 'Notice',
    component: () => import('../views/common/NoticeView.vue'),
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('../views/common/PrivacyView.vue'),
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('../views/common/TermsView.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/auth/LoginView.vue'),
    beforeEnter: ifAuthTrue,
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/auth/RegisterView.vue'),
    beforeEnter: ifAuthTrue,
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: () => import('../views/auth/ForgotPasswordView.vue'),
    beforeEnter: ifAuthTrue,
  },
  {
    path: '/destination',
    name: 'Destination',
    component: () => import('../views/spots/ListView.vue'),
  },
  // {
  //   path: '/destination*',
  //   redirect: '/destination/:page',
  // },
  {
    path: '/destination/:id',
    name: 'Destination Detail',
    component: () => import('../views/spots/DetailView.vue'),
  },
  {
    path: '/hashtag',
    name: 'Hashtag',
    component: () => import('../views/hashtags/ListView.vue'),
  },
  {
    path: '/map',
    name: 'Map',
    component: () => import('../views/maps/ListView.vue'),
  },
  {
    path: '/weekly-jeju',
    name: 'Weekly',
    component: () => import('../views/weekly/ListView.vue'),
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/profile/ProfileView.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/notice',
    name: 'Notice',
    component: () => import('../views/common/NoticeView.vue'),
  },
  {
    path: '/notice/:id',
    name: 'Notice Detail',
    component: () => import('../views/common/NoticeDetailView.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/contact/ContactView.vue'),
  },
  // {
  //   path: '*',
  //   name: 'NotFound',
  //   redirect: '/',
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta.auth && !store.getters.isLogin) {
    next('/login');
    return;
  }
  next();
});

export default router;
