import {
  saveAuthToCookie,
  saveUserToCookie,
  deleteCookie,
} from '@/utils/cookies.js';
import { loginUser, registerUser } from '@/api/auth';
import {
  fetchAllSpots,
  fetchLikesCount,
  fetchCommentsCount,
  fetchBookmarksCount,
  fetchDetailSpot,
  fetchTags,
  fetchComments,
} from '@/api/spot';
import { fetchAllTags } from '@/api/tag';
import { fetchMaps, fetchMapsByCat } from '@/api/map';
import {
  deleteBookmark,
  deleteLike,
  fetchBookmarksSpotList,
  fetchCommentsSpotList,
  fetchLikesSpotList,
  fetchProfile,
  fetchUserBookmarks,
  fetchUserLikes,
  setBookmark,
  setComment,
  setLike,
  unregister,
  updateUser,
} from '../api/profile';
import { fetchSpotsWithTag } from '@/api/tag';
import { saveIdToCookie } from '../utils/cookies';
import { fetchAllNotices, fetchNotice } from '../api/notice';
import { createContact } from '../api/contact';

const actions = {
  FETCH_SPOTS({ commit }, page) {
    fetchAllSpots(page)
      .then((res) => {
        commit('fetchSpots', res.data.data);
        commit('fetchSpotsLastPage', res.data.last_page);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  FETCH_LIKES_COUNT({ commit }, id) {
    fetchLikesCount(id)
      .then((res) => {
        commit('fetchLikesCount', res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  FETCH_COMMENTS_COUNT({ commit }, id) {
    fetchCommentsCount(id)
      .then((res) => {
        commit('fetchCommentsCount', res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  FETCH_BOOKMARKS_COUNT({ commit }, id) {
    fetchBookmarksCount(id)
      .then((res) => {
        commit('fetchBookmarksCount', res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  FETCH_SPOT({ commit }, id) {
    fetchDetailSpot(id)
      .then((res) => {
        let toNumber = res.data.geo_pos.split(',');
        let lat = toNumber[0];
        let lon = toNumber[1];
        commit('fetchSpot', res.data);
        commit('fetchSpotLat', lat);
        commit('fetchSpotLon', lon);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  FETCH_TAGS({ commit }, id) {
    fetchTags(id)
      .then((res) => {
        res.data.forEach((element) => {
          let arr = [];
          arr.push(element.tag);
          commit('fetchTags', arr);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  },
  FETCH_COMMENTS({ commit }, id) {
    fetchComments(id)
      .then((res) => {
        commit('fetchComments', res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  FETCH_ALL_HASHTAGS({ commit }) {
    fetchAllTags()
      .then((res) => {
        commit('fetchHashtags', res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  FETCH_MAPS({ commit }) {
    fetchMaps()
      .then((res) => {
        commit('fetchMaps', res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  FETCH_MAP_CATS({ commit }, catData) {
    fetchMapsByCat(catData)
      .then((res) => {
        commit('fetchMapsByCat', res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  // Auth
  async LOGIN({ commit }, userData) {
    const { data } = await loginUser(userData);
    saveIdToCookie(data.current_user.id);
    saveUserToCookie(data.current_user.email);
    saveAuthToCookie(data.access_token);
    commit('setId', data.current_user.id);
    commit('setEmail', data.current_user.email);
    commit('setToken', data.access_token);

    return data;
  },
  async REGISTER({ commit }, userData) {
    const { data } = await registerUser(userData);
    saveIdToCookie(data.current_user.id);
    saveUserToCookie(data.current_user.email);
    saveAuthToCookie(data.access_token);
    commit('setId', data.current_user.id);
    commit('setEmail', data.current_user.email);
    commit('setToken', data.access_token);

    return data;
  },
  async LOGOUT({ commit }) {
    deleteCookie('jtvr_userId');
    deleteCookie('jtvr_auth');
    deleteCookie('jtvr_user');
    commit('setId', '');
    commit('setToken', '');
    commit('setEmail', '');
  },
  UNREGISTER({ commit }, id) {
    deleteCookie('jtvr_userId');
    deleteCookie('jtvr_auth');
    deleteCookie('jtvr_user');
    commit('setId', '');
    commit('setToken', '');
    commit('setEmail', '');
    unregister(id)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  },
  UPDATE_USER({ commit }, userData) {
    updateUser(userData)
      .then((res) => {
        commit('updateUser', res.data);
      })
      .catch((err) => console.log(err));
  },
  FORGOT_PASSWORD() {},

  // Profile
  FETCH_USER({ commit }, id) {
    fetchProfile(id)
      .then((res) => {
        commit('fetchUser', res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  FETCH_USER_LIKES({ commit }, id) {
    fetchUserLikes(id)
      .then((res) => {
        commit('fetchUserLikes', res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  SET_LIKE(_, data) {
    setLike(data)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    // fetchLikesCount(data.place)
    //   .then((res) => {
    //     commit('fetchLikesCount', res.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  },
  FETCH_USER_BOOKMARKS({ commit }, id) {
    fetchUserBookmarks(id)
      .then((res) => {
        commit('fetchUserBookmarks', res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  SET_BOOKMARK(_, data) {
    setBookmark(data)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    // await fetchBookmarksCount(data.place)
    //   .then((res) => {
    //     commit('fetchBookmarksCount', res.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  },
  DELETE_LIKE(_, data) {
    deleteLike(data)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    // await fetchLikesCount(data.place)
    //   .then((res) => {
    //     commit('fetchLikesCount', res.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  },
  DELETE_BOOKMARK(_, data) {
    deleteBookmark(data)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    // await fetchBookmarksCount(data.place)
    //   .then((res) => {
    //     commit('fetchBookmarksCount', res.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  },
  SET_COMMENT(_, data) {
    setComment(data)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  FETCH_LIKES_SPOT_LIST({ commit }, id) {
    fetchLikesSpotList(id)
      .then((res) => {
        commit('fetchLikesSpotList', res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  FETCH_BOOKMARKS_SPOT_LIST({ commit }, id) {
    fetchBookmarksSpotList(id)
      .then((res) => {
        commit('fetchBookmarksSpotList', res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  FETCH_COMMENTS_SPOT_LIST({ commit }, id) {
    fetchCommentsSpotList(id)
      .then((res) => {
        commit('fetchCommentsSpotList', res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  // Hashtag
  FETCH_SPOTS_WITH_TAG({ commit }, payload) {
    fetchSpotsWithTag(payload)
      .then((res) => {
        commit('fetchSpotsWithTag', res.data.data);
        commit('fetchSpotsLastPage', res.data.last_page);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  // Notice
  FETCH_NOTICES({ commit }) {
    fetchAllNotices()
      .then((res) => {
        commit('fetchNoticeArticles', res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  FETCH_NOTICE({ commit }, id) {
    fetchNotice(id)
      .then((res) => {
        commit('fetchNoticeArticle', res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  // Contact
  CREATE_CONTACT({ commit }, payload) {
    createContact(payload)
      .then((res) => {
        commit('createContact', res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};

export default actions;
