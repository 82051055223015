function saveIdToCookie(value) {
  document.cookie = `jtvr_userId=${value}; max-age=2592000`;
}

function saveAuthToCookie(value) {
  document.cookie = `jtvr_auth=${value}; max-age=2592000`;
}

function saveUserToCookie(value) {
  document.cookie = `jtvr_user=${value}; max-age=2592000`;
}

function saveMaxAgeToCookie() {
  document.cookie = 'max-age=2592000';
}

function getIdFromCookie() {
  return document.cookie.replace(
    /(?:(?:^|.*;\s*)jtvr_userId\s*=\s*([^;]*).*$)|^.*$/,
    '$1'
  );
}

function getAuthFromCookie() {
  return document.cookie.replace(
    /(?:(?:^|.*;\s*)jtvr_auth\s*=\s*([^;]*).*$)|^.*$/,
    '$1'
  );
}

function getUserFromCookie() {
  return document.cookie.replace(
    /(?:(?:^|.*;\s*)jtvr_user\s*=\s*([^;]*).*$)|^.*$/,
    '$1'
  );
}

function deleteCookie(value) {
  document.cookie = `${value}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

export {
  saveIdToCookie,
  saveAuthToCookie,
  saveUserToCookie,
  saveMaxAgeToCookie,
  getIdFromCookie,
  getAuthFromCookie,
  getUserFromCookie,
  deleteCookie,
};
