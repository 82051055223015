import axios from 'axios';

import { setInterceptor } from './common/interceptor.js';

const createInstance = (url) => {
  return axios.create({
    baseURL: process.env.VUE_APP_API_URL + url,
  });
};

const createInstanceWithAuth = (url) => {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL + url,
  });

  return setInterceptor(instance);
};

const instanceAuth = createInstance('auth');
const instanceSpot = createInstanceWithAuth('destination');
const instanceTag = createInstanceWithAuth('hashtag');
const instanceMap = createInstanceWithAuth('map');
const instanceProfile = createInstanceWithAuth('my-profile');
const instanceNotice = createInstance('notice');
const instanceContact = createInstance('contact');

export {
  instanceAuth,
  instanceSpot,
  instanceTag,
  instanceMap,
  instanceProfile,
  instanceNotice,
  instanceContact,
};
